<template>
    <div class="full-height">
         <s-form @submit="addItem">
       <div class="row">
                <div class="col-12">
                    <h2 class="head font-poppins-semibold fs-lg-4 mt-3 text-primary">Nose Punching</h2>
                </div>
                <div class="col-lg-2 mt-lg-3">
                    <validated-ajax-vue-select class="c-input-select input-border-primary field-required" placeholder="Shed"
                                               v-model="shed_number" @input="loadCowOptions" label="Shed"
                                               :url="shedOptions" :rules="{required:true}"></validated-ajax-vue-select>
                </div>
            </div>
                    <p class="font-poppins-semibold fs-lg-2 text-primary mt-3"> Enter Individually</p>

        <div class="row ">
            <div class="col-lg-2">
                    <validated-vue-select :options="cowOptions" @input="loadDetails" class="c-input-select field-required"
                                          label="Animal No / Name" v-model="details.animalno_or_name" :rules="{required:true}"></validated-vue-select>
                </div>
            <div class="col-lg-2">
                <validated-input label="Renumber" class="text-black" v-model="details.renumber" disabled></validated-input>
            </div>
            <div class="col-lg-2">
                <validated-input label="Shed No" class="text-black" v-model="details.shed_number" disabled></validated-input>
            </div>
            <div class="col-lg-2">
                <validated-input label="Breed" class="text-black" v-model="details.breed" disabled></validated-input>
            </div>
            <div class="col-lg-2">
                <validated-input label="Date Of Birth" class="text-black" v-model="details.dob" disabled></validated-input>
            </div>
            <div class="col-lg-2">
                <validated-input label="Age" class="text-black" v-model="model.age" disabled></validated-input>
            </div>
        </div>
        <div class="pt-3 row">
            <div class="col-lg-2">
                <validated-date-picker format="DD-MM-YYYY" label="Nose Punching Date" class="c-input-datepicker text-black field-required" v-model="model.nosepunchingdate" :rules="rules.common"></validated-date-picker>
            </div>
        </div>
        <div class="row mt-lg-7">
            <div class="col-12">
                <div class="fl-x fl-j-e">
                    <div class="btn-group">
                        <btn text="Save" :loading="loading" loading-text="Saving..." class="px-4"></btn>
                        <btn text="Cancel" type="reset" @click="BackToHome" design="basic-b" class="px-4"></btn>
                   </div>
                </div>
            </div>
        </div>
         </s-form>

    </div>

</template>

<script>
import urls from '../../../data/urls';
import masterURLs from '../../../data/masterURLs';
import axios from 'secure-axios';

export default {
    name: 'CowsNosePunching',
    data () {
        return {
            shed_number: '',
            loading: false,
            URL: urls.cowNosePunching.addEdit,
            shedOptions: masterURLs.master.shed.vueSelect + '?animal=cow',
            cowOptions: [],
            details: {
                renumber: '',
                breed: '',
                dob: '',
                shed_number: '',
                animalno_or_name: ''
            },
            model: {
                cow: '',
                age: '',
                nosepunchingdate: ''
            },
            rules: {
                common: {
                    required: true,
                    customValidator: (value) => {
                        return this.Validation(value);
                    }
                }
            }
        };
    },
    methods: {
        BackToHome () {
            this.$router.push({ path: '/ah-section/' });
        },
        async loadCowOptions () {
            this.dataclear();
            const response = await axios.get(urls.cows.vueSelect + '?shed_number=' + this.shed_number);
            this.cowOptions = response.data.data;
        },
        dataclear () {
            this.model.age = '';
            this.model.cow = '';
            this.details.dob = '';
            this.details.renumber = '';
            this.details.breed = '';
            this.details.shed_number = '';
            this.details.animalno_or_name = '';
            this.model.nosepunchingdate = '';
        },
        async loadDetails () {
            const response = await axios.form(urls.cows.details, { id: this.details.animalno_or_name });
            const data = response.data.data;
            this.model.cow = data.id;
            this.model.age = data.age;
            console.log('Data', data);
            this.details.renumber = data.renumber;
            this.details.breed = data.breed.breed_name;
            this.details.dob = data.dob;
            this.details.shed_number = data.shedno.name;
            // this.details.animalno_or_name = data.animalno_or_name;
        },
        async addItem () {
            try {
                this.loading = true;
                const response = await axios.form(this.URL, this.model);
                const result = response.data;
                if (result.success) {
                    this.$notify('Added Successfully', 'Success', { type: 'success' });
                    this.loading = false;
                    await this.$router.push({ path: '/cows-list/' });
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}: ${errors[key]}`;
                            this.$notify('Warning', errorMessage, { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        },
        Validation (date) {
            const dob = this.details.dob;
            const dateParts = date.split('-');
            if (dateParts.length !== 3) {
                return 'Please enter a valid AI date in the format DD-MM-YYYY.';
            }
            const aiDay = parseInt(dateParts[0], 10);
            const aiMonth = parseInt(dateParts[1], 10);
            const aiYear = parseInt(dateParts[2], 10);

            const dobParts = dob.split('-');
            if (dobParts.length !== 3) {
                return 'Please enter a valid DOB in the format DD-MM-YYYY.';
            }
            const dobDay = parseInt(dobParts[0], 10);
            const dobMonth = parseInt(dobParts[1], 10);
            const dobYear = parseInt(dobParts[2], 10);

            if (isNaN(aiDay) || isNaN(aiMonth) || isNaN(aiYear) || isNaN(dobDay) || isNaN(dobMonth) || isNaN(dobYear)) {
                return 'Please enter valid dates in the format DD-MM-YYYY.';
            }

            const dateObj = new Date(aiYear, aiMonth - 1, aiDay);
            const dobDateObj = new Date(dobYear, dobMonth - 1, dobDay);

            if (dateObj <= dobDateObj) {
                return 'Date should be after the date of birth (DOB).';
            }
            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);
            if (dateObj > currentDate) {
                return 'Future Date not allowed.';
            }

            return true;
        }
    }
};
</script>

<style scoped>
</style>
